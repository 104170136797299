import { useMutation } from "react-query";
import axios from "axios";

const getUser = async (params) => {
  try {
    const token = sessionStorage.getItem("horai-token");
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/admin/logout`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("Invalid credentials");
  }
};

export const useLogoutAdmin = ({ ...config }) => {
  const { mutate: mutateLogoutAdmin, isLoading } = useMutation(getUser, {
    ...config,
  });

  return { mutateLogoutAdmin, isLoading };
};







const vendorLogout = async (params) => {
  try {
    const token = sessionStorage.getItem("horai-token");
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/vendor/logout`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("Invalid credentials");
  }
};

export const useLogoutVendor = ({ ...config }) => {
  const { mutate: mutateLogoutVendor, isLoading } = useMutation(vendorLogout, {
    ...config,
  });

  return { mutateLogoutVendor, isLoading };
};
