import { useState } from "react";

const useNotifications = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [notification, setNotification] = useState([]);

  const [loading, setLoading] = useState(true);

  return { unreadCount, loading, setUnreadCount,setNotification,notification
 };
};

export default useNotifications;
