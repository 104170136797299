// CustomHookContext.js
import useNotification from 'antd/es/notification/useNotification';
import React, { createContext, useContext } from 'react';

const CustomHookContext = createContext();

export function CustomHookNotificationProvider({ children }) {
  const customHookNotification = useNotification();

  return (
    <CustomHookContext.Provider value={customHookNotification}>
      {children}
    </CustomHookContext.Provider>
  );
}

export function useCustomNotificationHookContext() {
  return useContext(CustomHookContext);
}
