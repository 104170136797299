import React, { lazy, Suspense } from "react";

import { QueryClient, QueryClientProvider } from "react-query";

import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { UseAuth } from "./components/utils/utils";
import "antd/dist/reset.css";
import "./App.css";
import { Skeleton } from "antd";

import Main from "./components/layout/Main";
import "./main.css";
import "./responsive.css";
import useNotifications from "./components/context/useGetNotification";
import { CustomHookNotificationProvider } from "./components/context/notificationContext";

const queryClient = new QueryClient();

const Login = lazy(() => import("./components/features/Login/LoginContainer"));
const AdminLogin = lazy(() =>
  import("./components/features/AdminLogin/AdminLoginContainer")
);
const DashboardContainer = lazy(() =>
  import("./components/features/DashBoardContainer")
);
const AppointmentContainer = lazy(() =>
  import("./components/features/AppointmentCalender/AppointmentContainer")
);
const ManageEmployeeContainer = lazy(() =>
  import("./components/features/ManageEmployees/ManageEmployeeContainer")
);
const ManageAccountsContainer = lazy(() =>
  import("./components/features/ManageAccounts/ManageAccountsContainer")
);
const ManageOffersContainer = lazy(() =>
  import("./components/features/ManageMyOffer/ManageOfferContainer")
);
const ContactContainer = lazy(() =>
  import("./components/features/Contact/ContactContainer")
);
const StatisticContainer = lazy(() =>
  import("./components/features/Statistics/StatisticContainer")
);
const ManagePublicationContainer = lazy(() =>
  import("./components/features/ManagePublication/ManagePublicationContainer")
);
const ManageVendorContainer = lazy(() =>
  import("./components/features/ManageVendor/ManageVendorContainer")
);
const ReservationContainer = lazy(() =>
  import("./components/features/Reservation/ReservationContainer")
);
const ChatRoom = lazy(() =>
  import("./components/features/ChatRoom/ChatRoomContainer")
);

const LandingPage = lazy(() =>
  import("./components/features/LandingPage/LandingPageContainer")
);

const ResetPassword = lazy(()=>
import("./components/features/ForgotPassword/ForgotPassword"));

const ValidateOTP = lazy(()=>
  import("./components/features/ForgotPassword/ValidateOTP"));


function ProtectedRoute({ children }) {
  const isAuthenticated = UseAuth();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
}

let routes = [
  {
    path: "/",
    element: <LandingPage />,
    // errorElement:<ErrorPage/>
  },
  {
    path:"/reset-password/:token",
    element: <ResetPassword/>
  },
  {
    path:"/validate-otp",
    element: <ValidateOTP/>
  },
  {
    path: "/login",
    element: <Login />,
  },
  { path: "/admin", element: <AdminLogin /> },
  {
    path: "/reservations",
    element: (
      <ProtectedRoute>
        {/* <DashboardContainer> */}
        <Main>
          <Suspense fallback={<Skeleton active />}>
            <ReservationContainer />
          </Suspense>
        </Main>

        {/* </DashboardContainer> */}
      </ProtectedRoute>
    ),
  },

  {
    path: "/vendors",
    element: (
      <ProtectedRoute>
        {/* <DashboardContainer> */}
        <Main>
          <Suspense fallback={<Skeleton active />}>
            <ManageVendorContainer />
          </Suspense>
        </Main>

        {/* </DashboardContainer> */}
      </ProtectedRoute>
    ),
  },
  {
    path: "/customer",
    element: (
      <ProtectedRoute>
        {/* <DashboardContainer> */}
        <Main>
          <Suspense fallback={<Skeleton active />}>
            <ManageAccountsContainer />
          </Suspense>
        </Main>

        {/* </DashboardContainer> */}
      </ProtectedRoute>
    ),
  },
  {
    path: "/appointment",
    element: (
      <ProtectedRoute>
        {/* <DashboardContainer> */}
        <Main>
          <Suspense fallback={<Skeleton active />}>
            <AppointmentContainer />
          </Suspense>
        </Main>
        {/* </DashboardContainer> */}
      </ProtectedRoute>
    ),
    // errorElement:<ErrorPage/>
  },
  {
    path: "/employees",
    element: (
      <ProtectedRoute>
        {/* <DashboardContainer> */}
        <Main>
          <Suspense fallback={<Skeleton active />}>
            <ManageEmployeeContainer />
          </Suspense>
        </Main>

        {/* </DashboardContainer> */}
      </ProtectedRoute>
    ),
  },
  {
    path: "/accounts/:id",
    element: (
      <ProtectedRoute>
        <Main>
          <Suspense fallback={<Skeleton active />}>
            <ManageAccountsContainer />
          </Suspense>
        </Main>
      </ProtectedRoute>
    ),
  },

  {
    path: "/offers/:vendorId/:offerId",
    element: (
      <ProtectedRoute>
        <Main>
          <Suspense fallback={<Skeleton active />}>
            <ManageOffersContainer />
          </Suspense>
        </Main>
      </ProtectedRoute>
    ),
  },
  {
    path: "/offers",
    element: (
      <ProtectedRoute>
        <Main>
          <Suspense fallback={<Skeleton active />}>
            <ManageOffersContainer />
          </Suspense>
        </Main>
      </ProtectedRoute>
    ),
  },
  {
    path: "/contacts",
    element: (
      <ProtectedRoute>
        <Main>
          <Suspense fallback={<Skeleton active />}>
            <ContactContainer />
          </Suspense>
        </Main>
      </ProtectedRoute>
    ),
  },
  {
    path: "/statistics",
    element: (
      <ProtectedRoute>
        <Main>
          <Suspense fallback={<Skeleton active />}>
            <StatisticContainer />
          </Suspense>
        </Main>
      </ProtectedRoute>
    ),
  },
  {
    path: "/publications",
    element: (
      <ProtectedRoute>
        <Main>
          <Suspense fallback={<Skeleton active />}>
            <ManagePublicationContainer />
          </Suspense>
        </Main>
      </ProtectedRoute>
    ),
  },

  {
    path: "/chatroom/:roomId/:userId/:vendorId",
    element: (
      <ProtectedRoute>
        <CustomHookNotificationProvider>
          <Main>
            <Suspense fallback={<Skeleton active />}>
              <ChatRoom />
            </Suspense>
          </Main>
        </CustomHookNotificationProvider>
      </ProtectedRoute>
    ),
  },
];

const routesConfig = createBrowserRouter(routes);

function App() {
  const customHookNotification = useNotifications();
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Skeleton active />}>
        {/* <Provider store={store}> */}

        <div className="App">
          <RouterProvider router={routesConfig} />
        </div>

        {/* </Provider> */}
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
