import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  LogoutOutlined,
  TeamOutlined,
  TagsOutlined,
  CalendarOutlined,
  FileDoneOutlined,
  BarChartOutlined,
  FormOutlined,
} from "@ant-design/icons";

import React from "react";

export const categories = [
  "Mariage",
  "Fiançailles",
  "Anniversaire",
  "Fête des mères/pères",
  "Ramadan",
  "Noël",
  "Réveillon",
  "Halloween",
  "Admission Bac/l'université",
  "Fête de circoncision",
  "Enterrement de vie de jeune fille",
  "Accouchement",
  "Fête de la révélation du sexe",
];

const weddingCategories = [
  "event hall",
  "caterer",
  "beauty services",
  "women's fashion (dress)",
  "men's fashion (suit)",
  "photography",
  "florist",
  "gifts",
  "music",
  "decoration",
  "furniture",
  "pastry",
  "waiter/waitress (service)",
  "home appliances",
  "jewelry",
  "invitation",
  "travel agency",
  "sports",
];

export const categoriesList = weddingCategories.map((category) => ({
  value: category,
  label: category,
}));

export const offerList = ["Horai", "Thallo", "Auxo", "Carpo"];
export const vendorPopular = ["Yes", "No"];

export const usersList = [
  {
    id: 1,
    name: "rizwan",
    email: "rizwan@gmail.com",
    email_verified_at: null,
    premier_event: "Mariage",
    event_date: "10.23.23",
    stay_connected: "0",
    created_at: "2023-03-16T15:58:28.000000Z",
    updated_at: "2023-03-16T15:58:28.000000Z",
  },
  {
    id: 2,
    name: "rizwan",
    email: "rabbani1849@gmail.com",
    email_verified_at: null,
    premier_event: "Mariage",
    event_date: "10.23.23",
    stay_connected: "0",
    created_at: "2023-03-16T15:59:12.000000Z",
    updated_at: "2023-03-16T15:59:12.000000Z",
  },
];

export function UseAuth() {
  // call an API or check local storage to see if the user is logged in
  const isLoggedIn = sessionStorage.getItem("horai-token");
  return Boolean(isLoggedIn);
}

export const menuItem = [
  { name: "Manage my calender", icon: TeamOutlined, key: "/appointment" },
  { name: "Manage my offers", icon: TagsOutlined, key: "/offers" },
  // { name: "Manage my account", icon: LaptopOutlined, key: "/accounts" },
  { name: "My reservations", icon: CalendarOutlined, key: "/reservations" },
  {
    name: "Manage my publication",
    icon: FileDoneOutlined,
    key: "/publications",
  },

  { name: "contact", icon: FileDoneOutlined, key: "/contacts" },
  {
    name: "Manage my employees",
    icon: NotificationOutlined,
    key: "/employees",
  },
  { name: "NOTES", icon: FormOutlined, key: "/notes" },
  { name: "STATISTICS", icon: BarChartOutlined, key: "/statistics" },
];

export const menuItemAdmin = [
  {
    name: "Create new customer",
    icon: NotificationOutlined,
    key: "/customer",
  },
  { name: "Manage my vendor", icon: LaptopOutlined, key: "/vendors" },
];

export const IsAdmin = () => {
  const isAdmin = sessionStorage.getItem("horai-admin");

  return Boolean(isAdmin);
};

export const getFirstQueryError = (...rest) => {
  for (const param of rest) {
    if (param?.message) return param.message;
  }
};

export const isEmpty = (value) => {
  return !!value;
};

export const hasCommonRequiredFieldsEmpty = (state) => {
  const {
    email,
    categories,
    image,
    cover_picture,
    description,
    password,
    name,
  } = state;

  const isAuthenticationEmpty = isEmpty(email);
  const isTitleEmpty = isEmpty(name);
  const isDescriptionEmpty = isEmpty(description);
  const isStatusEmpty = isEmpty(categories);
  const isTypeEmpty = isEmpty(image);
  const isTargetUrlEmpty = isEmpty(cover_picture);
  const isusernameEmpty = isEmpty(password);

  if (
    isAuthenticationEmpty &&
    isTitleEmpty &&
    isTargetUrlEmpty &&
    isDescriptionEmpty &&
    isStatusEmpty &&
    isTypeEmpty &&
    isusernameEmpty
  ) {
    return true;
  }

  return false;
};

export const getHoursPassed = (time) => {
  const givenDate = new Date(time);
  const currentDate = new Date();

  const timeDifferenceInSeconds = Math.floor((currentDate - givenDate) / 1000);

  if (timeDifferenceInSeconds < 60) {
    return timeDifferenceInSeconds + " seconds ago";
  } else if (timeDifferenceInSeconds < 3600) {
    const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
    return minutesAgo + (minutesAgo === 1 ? " minute ago" : " minutes ago");
  } else if (timeDifferenceInSeconds < 86400) {
    const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
    return hoursAgo + (hoursAgo === 1 ? " hour ago" : " hours ago");
  } else if (timeDifferenceInSeconds < 604800) {
    const daysAgo = Math.floor(timeDifferenceInSeconds / 86400);
    return daysAgo + (daysAgo === 1 ? " day ago" : " days ago");
  } else {
    const weeksAgo = Math.floor(timeDifferenceInSeconds / 604800);
    return weeksAgo + (weeksAgo === 1 ? " week ago" : " weeks ago");
  }
};
